/* eslint-disable import/prefer-default-export */
import { AgendaActionCreators } from '../reducers/agendaReducer';
import { getAgendaService, signUserToShiftService} from '../services/agenda';

export const getAgenda = () => async dispatch => {
	dispatch(AgendaActionCreators.requestAgenda());
	try {
		const response = await getAgendaService();
		dispatch(
			AgendaActionCreators.successfulAgenda({
				result: response
			})
		);
	} catch (e) {
		// dispatch(MessageActions.showMessage({ message: e.message, variant: 'error' }));
		dispatch(AgendaActionCreators.errorAgenda(e));
	}
};

export const signUserToShift = (values, addToast, history, initPoint) => async dispatch => {
	dispatch(AgendaActionCreators.requestAgenda());
	try {
		await signUserToShiftService(values);
		dispatch(
			AgendaActionCreators.successfulSignUser({
				result: values
			})
		);
		console.log(initPoint)
		if(initPoint){
			window.location.href=initPoint;
		}
	} catch (err) {
		addToast(err.message, {
			appearance: "error",
			autoDismiss: true,
		  });
		dispatch(AgendaActionCreators.errorAgenda(err));
	}
}